<template>
  <div>
    <v-dialog
      persistent
      transition="dialog-top-transition"
      v-model="openSettings"
      max-width="400"
    >
      <v-card shaped light :ref="'settingsCard'">
        <v-card-title> Settings </v-card-title>
        <v-card-text class="pl-10 pr-10">
          <v-row>
            <v-select
              v-model="selectedCardType"
              :items="cardTypes"
              item-text="type"
              item-value="value"
              label="Select Card Type"
              @change="changeCards('cardType')"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="selectedLevel"
              :items="level"
              item-text="type"
              item-value="value"
              label="Select Level"
              @change="changeCards('level')"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="selectedLanguage"
              :items="languages"
              item-text="text"
              item-value="value"
              label="Select Language"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="selectedVoiceSpeedPreference"
              :items="voiceSpeedPreference"
              item-text="speed"
              item-value="value"
              label="Select Voice Speed"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="selectedVoiceGender"
              :items="voiceGenderPreference"
              item-text="gender"
              item-value="value"
              label="Select Voice Gender"
            ></v-select>
          </v-row>
          <v-row>
            <v-icon
              color="primary"
              class="mr-5"
              @click="frontCardTypeGuj = !frontCardTypeGuj"
              >mdi-rotate-3d-variant</v-icon
            >
            <span>{{ cardRotateMode }}</span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            raised
            color="primary"
            class="mr-5"
            @click="toggleSettings()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-carousel
        :continuous="false"
        hide-delimiters
        :style="{ width: $vuetify.breakpoint.mdAndUp ? '450px' : '425px' }"
        class="customArrow"
        height="550px"
        ref="carousel"
      >
        <v-carousel-item
          v-for="(card, i) in flashCards"
          :key="'carouselItem' + card.serial"
        >
          <div class="scene pa-5">
            <div class="card" :ref="'card' + card.serial">
              <audio id="localaudio" @ended="voiceIcon = 'mdi-play'"></audio>
              <div class="card__face card__face--front">
                <FlashCard
                  :cardHeader="card[frontCardLang].header"
                  :cardSubHeader="card[frontCardLang].subheader"
                  :cardSpeak="card[frontCardLang].speak"
                  :imgUrl="calculatedImageId(card)"
                  :voiceIcon="voiceIcon"
                  @turn="toggleCard('card' + card.serial, i)"
                  @play="play('front')"
                  @toggleSetting="toggleSettings()"
                  :ref="selectedCardType + i + 'front'"
                  :style="
                    card.side !== 'front' && card.side !== 'both'
                      ? { display: 'none' }
                      : {}
                  "
                />
              </div>
              <div class="card__face card__face--back">
                <FlashCard
                  :voiceIcon="voiceIcon"
                  :cardHeader="card[backCardLang].header"
                  :cardSubHeader="card[backCardLang].subheader"
                  :cardSpeak="card[backCardLang].speak"
                  :imgUrl="calculatedImageId(card)"
                  @turn="toggleCard('card' + card.serial, i)"
                  @play="play('back')"
                  @toggleSetting="toggleSettings()"
                  :style="
                    card.side !== 'back' && card.side !== 'both'
                      ? { display: 'none' }
                      : {}
                  "
                  :ref="selectedCardType + i + 'back'"
                />
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row justify="center">
      <div>
        <v-icon
          medium
          :style="{ width: '25%' }"
          :color="selectedIndex === 0 ? 'gray' : 'primary'"
          @click="previous()"
          >mdi-arrow-left</v-icon
        >
        <v-btn
          text
          :style="{
            width: '50%',
            pointerEvents: 'none',
            textTransform: 'none'
          }"
          >Card {{ selectedIndex + 1 + '/' + flashCards.length }}</v-btn
        >
        <v-icon
          medium
          :style="{ width: '25%' }"
          :color="selectedIndex === flashCards.length - 1 ? 'gray' : 'primary'"
          @click="next()"
          >mdi-arrow-right</v-icon
        >
        <GoBackButton />
      </div>
    </v-row>
  </div>
</template>
<script>
import GoBackButton from './GoBackButton'
import FlashCard from './FlashCard'
import Utils from '../util/Utils'
import { mapGetters } from 'vuex'
export default {
  components: { GoBackButton, FlashCard },
  data: function () {
    return {
      showSide: 'front',
      voiceSpeedPreference: [
        { speed: 'Slow', value: 0.5 },
        { speed: 'Normal', value: 0.75 },
        { speed: 'Fast', value: 1 }
      ],
      voiceGenderPreference: [
        { gender: 'Male', value: 'MALE' },
        { gender: 'Female', value: 'FEMALE' }
      ],
      selectedLanguage: 'en',
      level: [],
      selectedLevel: 'ગ, મ, ન, જ',
      selectedVoiceGender: 'FEMALE',
      selectedVoiceSpeedPreference: 0.75,
      selectedCardType: 'New Mulakshar Words',
      speed: 2,
      selectedIndex: 0,
      loading: false,
      selection: 1,
      test: false,
      sound: '',
      openSettings: true,
      settingsCardHeight: 300,
      voiceIcon: 'mdi-play',
      defaultVoiceIcon: 'mdi-play',
      flashCards: [],
      frontCardTypeGuj: false
    }
  },
  computed: {
    ...mapGetters(['langs']),
    cardTypes() {
      return Object.keys(this.$store.state.flashcards).map((t) => {
        return { type: t, value: t }
      })
    },
    calcuatedSpeed() {
      if (this.speed === 2) {
        return 1
      } else if (this.speed === 1) {
        return 0.75
      } else {
        return 0.5
      }
    },
    languages() {
      return [
        { text: 'English', value: 'en', speechCode: 'en-US' },
        { text: 'हिन्दी', value: 'hi', speechCode: 'hi-IN' },
        { text: 'Deutsch', value: 'de', speechCode: 'de-DE' }
      ]
    },
    cardRotateMode() {
      const selectedLangText = this.languages.find(
        (l) => l.value === this.selectedLanguage
      ).text
      return this.frontCardTypeGuj
        ? `ગુજરાતી to ${selectedLangText}`
        : `${selectedLangText} to ગુજરાતી`
    },
    frontCardLang() {
      return this.frontCardTypeGuj ? 'gu' : this.selectedLanguage
    },
    backCardLang() {
      return this.frontCardTypeGuj ? this.selectedLanguage : 'gu'
    }
  },
  mounted() {
    this.changeCards()
    this.openSettings = true
  },
  methods: {
    frontcardText() {
      return this.flashCards[this.selectedIndex][this.frontCardLang].header
    },
    calculatedImageId(card) {
      return Utils.imgUrlByPath(`flashcards/${card.imgUrl || card.serial}.jpg`)
    },
    changeCards(changeType) {
      this.level = []
      Utils.groupBy(
        this.$store.state.flashcards[this.selectedCardType],
        (card) => card.level
      ).forEach((value, key) => {
        this.level.push({ type: key, value: key })
      })
      if (changeType === 'cardType') this.selectedLevel = this.level[0].value
      this.flashCards = this.$store.state.flashcards[this.selectedCardType]
        .filter((c) => c.level === this.selectedLevel)
        .map((c) => {
          return { ...c, side: 'front' }
        })
      this.selectedIndex = 0
      if (this.flashCards[this.selectedIndex].Side === 'back') {
        this.toggleCard('card' + this.flashCards[this.selectedIndex].serial)
      }

      while (this.$refs.carousel.hasPrev) {
        this.$refs.carousel.prev()
      }
    },
    displayedText() {
      if (this.flashCards[this.selectedIndex].side === 'front') {
        return this.flashCards[this.selectedIndex][this.frontCardLang].header
      } else {
        return this.flashCards[this.selectedIndex][this.backCardLang].header
      }
    },
    speechText() {
      if (this.flashCards[this.selectedIndex].side === 'front') {
        return (
          this.flashCards[this.selectedIndex][this.frontCardLang].speak ||
          this.flashCards[this.selectedIndex][this.frontCardLang].header
        )
      } else {
        return (
          this.flashCards[this.selectedIndex][this.backCardLang].speak ||
          this.flashCards[this.selectedIndex][this.backCardLang].header
        )
      }
    },
    toggleSettings() {
      const name =
        this.selectedCardType +
        this.selectedIndex +
        this.flashCards[this.selectedIndex].side
      this.openSettings = !this.openSettings

      if (this.openSettings) {
        const height = this.$refs[name][0].$el.clientHeight + 50
        setTimeout(() => {
          this.settingsCardHeight = height
        }, 400)
      }
    },
    toggleCard: function (cardNum, index) {
      var audio = document.getElementById('localaudio')
      audio.pause()
      this.voiceIcon = this.defaultVoiceIcon
      const currentSide = this.flashCards[index].side
      this.flashCards[index].side = 'both'
      this.$refs[cardNum][0].classList.toggle('flip')
      setTimeout(() => {
        if (currentSide === 'front') {
          this.flashCards[index].side = 'back'
        } else {
          this.flashCards[index].side = 'front'
        }
      }, 500)
    },
    next() {
      const next = document.querySelector('.v-window__next button')
      if (next) {
        next.click()
      }
      if (this.selectedIndex + 1 < this.flashCards.length) {
        if (this.flashCards[this.selectedIndex].side !== 'front') {
          this.toggleCard(
            'card' + this.flashCards[this.selectedIndex].serial,
            this.selectedIndex
          )
        }
        this.selectedIndex++
        this.flashCards[this.selectedIndex].side = 'front'
      }
    },
    previous() {
      const prev = document.querySelector('.v-window__prev button')
      if (prev) {
        prev.click()
      }
      if (this.selectedIndex > 0) {
        if (this.flashCards[this.selectedIndex].side !== 'front') {
          this.toggleCard(
            'card' + this.flashCards[this.selectedIndex].serial,
            this.selectedIndex
          )
        }
        this.selectedIndex--
        this.flashCards[this.selectedIndex].side = 'front'
      }
    },
    play(side) {
      this.voiceIcon = 'mdi-pause'
      var lang =
        side === 'back'
          ? !this.frontCardTypeGuj
            ? 'gu-IN'
            : this.languages.find((l) => l.value === this.backCardLang)
                .speechCode
          : this.frontCardTypeGuj
          ? 'gu-IN'
          : this.languages.find((l) => l.value === this.frontCardLang)
              .speechCode
      var audio = document.getElementById('localaudio')
      audio.src = `https://us-central1-learn-gujarati-b1468.cloudfunctions.net/tts?text=${this.speechText(
        this.selectedIndex
      )}&lang=${lang}&speed=${this.selectedVoiceSpeedPreference}&gender=${
        this.selectedVoiceGender
      }`
      audio.play()
    }
  }
}
</script>
<style scoped>
.scene {
  width: 100%;
  height: 100%;
  margin: 40px 0;
  perspective: 1200px;
}
.card {
  position: relative;
  width: 100%;
  height: 68%;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}
.flip {
  transform: rotateY(-180deg);
}
.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  backface-visibility: hidden;
}
.card__face__card {
  backface-visibility: hidden;
}
.card__face--front {
}
.card__face--back {
  transform: rotateY(180deg);
}
.voice {
  border-radius: 12px;
  box-shadow: 0 0 0 0 rgba(217, 84, 89, 1);
  animation: pulse-red 2s infinite;
}
@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(217, 84, 89, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0);
  }
}
.settings {
  position: absolute;
  content: '';
  bottom: 0;
  width: 100%;
  height: 18%;
  color: #fff;
  transition: all 0.4s;
  overflow: hidden;
  pointer-events: none;
  left: 0;
  backface-visibility: hidden;
}
.settings:before {
  position: absolute;
  content: '';
  left: 93%;
  bottom: 0px;
  width: 100%;
  height: 104%;
  z-index: 0;
  opacity: 1;
  color: #fff;
  background-color: #030303;
  transition: all 0.4s;
  transform: skewX(-50deg);
  backface-visibility: hidden;
}
.settingsIcon {
  position: absolute;
  right: 5px;
  z-index: 0;
  bottom: 6px;
}
.settingsIcon:hover {
  animation: infinite-spinning 1s ease-out 0s normal;
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.settingsCard {
  position: absolute;
  z-index: 1;
}
.settingsCardClose {
  position: absolute;
  bottom: 10px;
  left: 36%;
}
/** Hide carousel arrows */
.customArrow >>> .v-window__prev {
  left: 62px;
  z-index: -1;
  background: none;
}
.customArrow >>> .v-window__next {
  right: 62px;
  z-index: -1;
  background: none;
}
</style>
